/******************************************/
/* imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');

/******************************************/
/* variables */
:root {
  --colorA: #000000;  /* primary */
  --colorB: #333333;  /* midpoint */
  --colorC: #FFFFFF;  /* secondary */
  --color1: #0088AA;
  --color2: #D5F6FF;
  --color3: #FFFFFF;

  /*--colorD: #777777;  /* form, element */
  --color4: #777777;

  --priBack: var(--colorC);
  --priFore: var(--colorA);
  --secBack: var(--color3);
  --secFore: var(--color1);
  --midBack: var(--color2);
  --midFore: var(--colorB);

  --frmFore: var(--color4);

  --pageMaxWidth: 800px;
  --pageMaxShortWidth: 360px;
  --pageCornerRadius: 6px;

  --fontSizeMain:   16px;
  --fontSizeLogo:   20px;
  --fontSizeNav1:   16px;
  --fontSizeNav2:   12px;
  --fontSizeHeading: 24px;
  --fontSizeButton: 18px;
  --fontSizeInputs: 18px;
}

/******************************************/
/* general */
body {
  background: var(--priBack);
  color: var(--priFore);
  font-family: Roboto, sans-serif;
  font-size: var(--fontSizeMain);
  margin: 0;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
a {
  color: inherit;
  text-decoration: none;
}


/******************************************/
/* flex spread */
.spread {
  flex-grow: 100;
}

/******************************************/
/* content */
.content {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;  
  margin: 0 auto;
  /* max-width: var(--pageMaxWidth); */
  padding: 0px 10px;
  width: 100%;
}
.content>div {
  margin: 0 auto;
  max-width: var(--pageMaxWidth);
  width: 100%;
}
.content .short {
  margin: 0 auto;
  max-width: var(--pageMaxShortWidth);
  width: 100%;
}
.content .wide {
  margin: 0 auto;
  max-width: none;
  padding: 0px 10px;
  width: 100%;
}


h2 {
  border-bottom: solid 1px #A0A0A0;
  font-size: var(--fontSizeHeading);
  margin-bottom: 10px;
  padding-bottom: 6px;
  padding-top: 36px;  
  text-transform: lowercase;
}

h4 {
  border-bottom: solid 1px #A0A0A0;
  margin-bottom: 10px;
  padding-bottom: 6px;
  padding-top: 18px;  
  text-transform: lowercase;
}

/******************************************/
/* forms */
form label {
  display: block;
  margin: 20px auto;
}
form span {
  display: block;
  margin-bottom: 4px;
}
form input {
  border-radius: var(--pageCornerRadius);
  box-sizing: border-box;  
  color: var(--frmFore);
  font-size: var(--fontSizeInputs);
  padding: 6px 6px;
  width: 100%;
}
button {
  background: none;
  border: 2px solid var(--secFore);
  border-radius: var(--pageCornerRadius);
  color: var(--secFore);
  cursor: pointer;
  font-size: var(--fontSizeButton);
  font-weight: bold;
  padding: 4px 8px;
}
button:hover {
  background: var(--secFore);
  border-color: var(--secFore);
  color: var(--secBack);
}
button.small {
  font-size: 12px;
  padding: 3px 4px;
}

/******************************************/
/* list */
.list>* {
  border: 1px solid #C0C0C0;
  border-radius: var(--pageCornerRadius);
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 5px 10px;
}
.list>*:hover {
  background: var(--midBack);
  color: var(--midFore);
}


/******************************************/
/* items (vertical) */
.items-v {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
}
.items-v>* {
  align-items: center;
  display: flex;
  margin-top: 6px;
}
.items-v>*:first-child {
  margin-top: 0px;
}


/******************************************/
/* items (horizontal) */
.items-h {
  align-items: center;
  display: flex;
  padding: 0px;
  width: 100%;
}
.items-h>* {
  align-items: center;
  display: flex;
  margin-left: 6px;
}
.items-h>*:first-child {
  margin-left: 0px;
}


/******************************************/
/* notices */
.notice {
  border: 1px solid #C0C0C0;
  border-radius: var(--pageCornerRadius);
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
  box-sizing: border-box;
  margin: 5px 0px;
  padding: 5px 10px;
}
.success {
  background: #c0ffc0;
  color: #30a030;
  font-size: 0.9em;
  font-style: italic;
  font-weight: bold;
}
.error {
  background: #ffc0c0;
  color: #ff3030;
  font-size: 0.9em;
  font-style: italic;
  font-weight: bold;
}






















/* Error Notification */
.notify_err {
  background: #ffc0c0;
  border: 1px solid #ff3030;
  color: #ff3030;
  font-size: 0.9em;
  font-style: italic;
  font-weight: bold;
  margin: 12px 0;
  padding: 6px 12px;
  width: 100%;
}

