
/* Container */
.navbar1, .navbar2 {
  box-sizing: border-box;
  padding: 8px 0px;
  width: 100%;
}
.navbar1 {
  background: var(--midFore);
  color: var(--priBack);
  font-size: var(--fontSizeNav1);
}
.navbar2 {
  background: var(--secFore);
  color: var(--priBack);
  font-size: var(--fontSizeNav2);
}

.navbar1 ul, .navbar2 ul {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: var(--pageMaxWidth);
  padding: 0px 10px;
}

.navbar1 ul {
  height: 36px;
}
.navbar2 ul {
  height: 12px;
}

.navbar1 li {
  cursor: default;
  margin-left: 16px;
}
.navbar2 li {
  cursor: default;
  margin-left: 16px;
}
.navbar1 li:first-child {
  margin-left: 0px;
}
.navbar2 li:first-child {
  margin-left: 0px;
}



/* Logo/Title */
.navbar1 .title {
  align-items:center;
  display: flex;
  font-size: var(--fontSizeLogo);
  font-weight: bold;
  margin-left: 0px;
  margin-right: auto;
}

/* Links */
.navbar1 a, .navbar1 span, .navbar2 a, .navbar2 span {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

